.module-border-wrap {
  background: linear-gradient(to right, #6a468a, #b49ec8);
  padding: 3px;
}

.module {
  background: #191919;
  padding: 61px 24px;
}

.fs-2rem {
  font-size: 2rem;
}

.collection-item-custom {
  background: transparent !important;
}

.contact-heading {
  margin: 0px 0px 45px 0px;
}

.dark-purple {
  color: #300f4f;
}

.bg-white {
  background: white;
}

.fw-bold {
  font-weight: bold;
}

.input-field input {
  color: #300f4f !important;
}

/* label color */
.input-field label {
  color: #300f4f !important;
}
/* label focus color */
.input-field input[type]:focus + label {
  color: #300f4f;
}
/* label underline focus color */
.input-field input[type]:focus {
  border-bottom: 1px solid #300f4f !important;
  box-shadow: 0 1px 0 0 #300f4f !important;
}
/* valid color */
.input-field input[type].valid {
  border-bottom: 1px solid #000 !important;
  box-shadow: 0 1px 0 0 #000 !important;
}
/* invalid color */
.input-field input[type].invalid {
  border-bottom: 1px solid #000 !important;
  box-shadow: 0 1px 0 0 #000 !important;
}
/* icon prefix focus color */
.input-field .prefix.active {
  color: #000 !important;
}

/* label focus color */
.input-field textarea + label {
  color: #300f4f !important;
}
/* label underline focus color */
.input-field textarea:focus {
  border-bottom: 1px solid #300f4f !important;
  box-shadow: 0 1px 0 0 #300f4f !important;
}

.input-field textarea:focus + label {
  color: #300f4f !important;
}

.contact-col {
  margin-top: 67px;
  margin-bottom: 67px;
  padding: 60px 60px !important;
}

.btn-submit {
  background: white;
  color: #300f4f;
  margin-right: 10px;
}

.btn-submit:hover {
  background: #300f4f;
  color: #fff;
}

.contact-row {
  margin-bottom: 0px;
}

body,
html {
  height: 100% !important;
  margin: 0 !important;
  font-size: 16px !important;
  font-family: "Lato", sans-serif !important;
  font-weight: 400 !important;
  line-height: 1.8em !important;
  color: #666 !important;
}

.pimg1,
.pimg2,
.pimg3,
.pimg4 {
  position: relative !important;
  opacity: 0.7 !important;
  background-position: center !important;
  background-size: cover !important;
  background-repeat: no-repeat !important;

  /*
    fixed = parallax
    scroll = normal
  */
  background-attachment: fixed;
}

.pimg1 {
  background-image: url("./assets/fibre.jpg");
  min-height: 520px;
}

.pimg2 {
  background-image: url("./assets/react.jpg");
  min-height: 300px;
}

.pimg3 {
  background-image: url("./assets/white_fibre.jpg");
  min-height: 400px;
}

.pimg4 {
  background-image: url("./assets/hamilton.jpg");
  min-height: 400px;
}

.ptext-first {
  top: 20% !important;
}

.name {
  margin: 25px 0px 15px 0px;
}

.section {
  text-align: center;
  padding: 50px 80px;
}

.section-light {
  background-color: #f4f4f4;
  color: #666;
}

.section-dark {
  background-color: #282e34;
  color: #ddd;
}

.ptext {
  position: absolute;
  top: 50%;
  width: 100%;
  text-align: center;
  color: #000;
  font-size: 27px;
  letter-spacing: 8px;
}

.ptext .border {
  background-color: #111;
  color: #fff;
  padding: 20px;
}

.ptext .border.trans {
  background-color: transparent;
}

a {
  color: #fff;
  text-decoration: none;
}

a:visited {
  color: rgb(0, 225, 255);
}

a:hover {
  color: rgb(0, 162, 255);
}

@media (max-width: 568px) {
  .pimg1,
  .pimg2,
  .pimg3 {
    background-attachment: scroll;
  }
}
